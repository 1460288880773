<template>
  <v-card>
    <v-container class="p-0">
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="4" class="py-0 px-1">
              <v-text-field
                v-model.trim="condIva.ivaNom"
                label="Nombre"
                dense
                outlined
                hide-details="auto"
                :rules="
                  rules.required.concat([
                    rules.maxLength(condIva.ivaNom, 50),
                    rules.requiredTrim(condIva.ivaNom)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0 px-1">
              <v-text-field
                v-model.trim="condIva.ivaSigla"
                label="Sigla"
                dense
                outlined
                hide-details="auto"
                :rules="
                  rules.required.concat([
                    rules.maxLength(condIva.ivaSigla, 5),
                    rules.requiredTrim(condIva.ivaSigla)
                  ])
                "
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" class="py-0 px-1">
              <v-text-field
                v-model.trim="condIva.tipoResponsableCodigo"
                label="Tipo de responsable"
                dense
                v-mask="'##'"
                hint="Código de tipo de responsable en Factura Electrónica"
                hide-details="auto"
                outlined
                :rules="[rules.maxLength(condIva.tipoResponsableCodigo, 2)]"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pt-0 pb-2">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditCondicionesIva",
  directives: { mask },

  props: {
    objIva: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_COND_IVA,
    newTitle: enums.titles.NUEVA_COND_IVA,
    rules: rules,
    condIva: {}
  }),
  created() {
    if (this.objIva) {
      this.setCondIva(this.objIva);
    } else {
      this.newIva();
    }
  },
  methods: {
    ...mapActions({
      getCondicionIvaById: "configuracion/getCondicionIvaById",
      saveCondicionIva: "configuracion/saveCondicionIva",
      setAlert: "user/setAlert"
    }),

    async setCondIva() {
      this.condIva = this.objIva;
    },
    async newIva() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      const data = {
        ivaId: this.condIva.ivaId,
        ivaNom: this.condIva.ivaNom,
        ivaSigla: this.condIva.ivaSigla,
        tipoResponsableCodigo: this.condIva.tipoResponsableCodigo ?? null
      };
      const res = await this.saveCondicionIva(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
